<template>
    <LnwmeLayout v-bind="{ myOrderDrawer, select_mode}"
                 @loadMoreOrders="loadMoreOrders()"
                 :isMyOrder=true>
        <template #toolbar>
            <template v-if="!select_mode">
                <v-app-bar-nav-icon>
                    <template v-if="isDesktop">
                        <v-icon @click.stop="myOrderDrawer = !myOrderDrawer">
                            {{ myOrderDrawer ? 'mdi-menu-open' : 'mdi-storefront-outline' }}
                        </v-icon>
                    </template>
                    <template v-else>
                        <v-icon @click.stop="myOrderDrawer = !myOrderDrawer">
                            mdi-menu
                        </v-icon>
                    </template>
                </v-app-bar-nav-icon>
            </template>
            <template v-else>
                <v-app-bar-nav-icon>
                    <v-btn icon
                           @click="selectedItems = []; select_mode = false;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar-nav-icon>
            </template>
            <v-toolbar-title style="text-overflow: inherit; overflow: visible;"
                             :class="(isDesktop)?'':'text-body-1 pl-0'">
                <template v-if="select_mode">
                    {{ selectedItems.length + ' ' + (isDesktop ? $t('รายการเลือกแล้ว') : $t('รายการ')) }}
                    <v-btn text
                           :disabled="selectedItems.length > 0 && selectedItems.length == orderByStatusWaitPayment.length"
                           :style="(isDesktop)?'':'min-width: 48px;'"
                           :class="(isDesktop)?'ml-6':''"
                           @click="selectAllWaitPayment">
                        <v-icon>mdi-check-all</v-icon>
                        <template v-if="isDesktop">
                            {{ $t('เลือกทั้งหมดที่รอชำระเงิน') }}
                        </template>
                    </v-btn>
                </template>
                <template v-else>
                    {{ $t('ออเดอร์ของฉัน') }}
                </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-if="!select_mode">
                <v-btn text
                       :style="(isDesktop)?'':'min-width: 48px;'"
                       :class="(isDesktop)?'mr-3':'px-0'"
                       @click="select_mode = true;">
                    <template v-if="!isDesktop">
                        <v-icon>mdi-selection</v-icon>
                    </template>
                    <template v-else>
                        {{ $t('เลือกรายการ') }}
                    </template>
                </v-btn>
                <v-btn depressed
                       dark
                       color="primary"
                       :text="isDesktop"
                       :style="(isDesktop)?'':'min-width: 48px;'"
                       :class="(isDesktop)?'mr-3':'px-0'"
                       @click="informDialog = true">
                    <template v-if="!isDesktop">
                        <v-icon>mdi-receipt-text-plus</v-icon>
                    </template>
                    <template v-else>
                        {{ $t('แจ้งโอนเงิน') }}
                    </template>
                </v-btn>
            </template>
            <v-btn v-else
                   :disabled="!selectedItems.length"
                   depressed
                   color="white"
                   class="mr-3 primary--text"
                   @click="informDialog = true">
                {{ (isDesktop) ? $t('แจ้งโอนเงิน') : $t('แจ้งโอน') }}
            </v-btn>
        </template>
        <template #drawer>
            <v-navigation-drawer v-if="shopData && userData"
                                 v-model="myOrderDrawer"
                                 app
                                 left
                                 :permanent="isDesktop"
                                 :width="myOrderDrawer?320:0"
                                 style="height: 100%; overflow: auto; z-index: 1021; box-sizing: border-box;">
                <v-list class="pt-4 px-4 pb-1 sticky-top">
                    <v-list-item two-line
                                 link
                                 dense
                                 :href="shopData.url"
                                 target="_blank"
                                 class="text-decoration-none elevation-24 border bg-blurry">
                        <v-list-item-action>
                            <v-img width="40"
                                   :aspect-ratio="1"
                                   class="rounded-lg"
                                   :src="shopData.avatar"></v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><b>{{ shopData.name }}</b></v-list-item-title>
                            <v-list-item-subtitle style="font-size: 0.8em;">
                                {{ shopData.domain }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon color="primary"
                                    small>
                                mdi-open-in-new
                            </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <!--                <v-divider></v-divider>-->
                <v-list dense
                        subheader>
                    <v-subheader style="margin: 5px 5px -10px;">{{ $t('ระดับสมาชิกของฉัน') }}</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <div v-if="userDataInShop && userDataInShop.is_join"
                                     class="d-inline-flex flex-wrap">
                                    <template v-if="userDataInShop.user_level_names && userDataInShop.user_level_names.length > 0">
                                        <v-tooltip top
                                                   v-for="(level, index) in userDataInShop.user_level_names"
                                                   :key="index">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        class="m-1"
                                                        color="primary"
                                                        :class="(userDataInShop.user_level_names.length > 1)?'mr-2':''">
                                                    {{ level }}
                                                </v-chip>
                                            </template>
                                            {{ $t('ระดับสมาชิกของฉัน') }}
                                        </v-tooltip>
                                    </template>
                                    <template v-else>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        class="my-1"
                                                        color="primary">
                                                    {{ $t('สมาชิกร้านทั่วไป') }}
                                                </v-chip>
                                            </template>
                                            {{ $t('คุณยังไม่ได้รับระดับสมาชิกของร้านนี้') }}
                                        </v-tooltip>
                                    </template>
                                    <template v-if="userDataInShop.is_dealer && userDataInShop.dropship_level_name">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        class="m-1"
                                                        dark
                                                        color="pink">
                                                    {{ userDataInShop.dropship_level_name }}
                                                </v-chip>
                                            </template>
                                            {{ $t('ระดับสมาชิกตัวแทนของคุณ') }}
                                        </v-tooltip>
                                    </template>
                                </div>
                                <div v-else
                                     class="just_flex_start"> <!-- ยังไม่ได้ Join -->
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip v-bind="attrs"
                                                    v-on="on"
                                                    small
                                                    class="m-1 px-3 pl-2"
                                                    color="#f5f5f5">
                                                <v-icon small
                                                        class="mx-1">mdi-account-clock
                                                </v-icon>
                                                {{ $t('ยังไม่ได้เป็นสมาชิกร้าน') }}
                                            </v-chip>
                                        </template>
                                        {{ $t('คุณยังไม่ได้ Join เป็นสมาชิกร้านนี้') }}
                                    </v-tooltip>
                                    <join-dialog :fetchMyOrderList="true">
                                        <template v-slot:activator="{on,attrs}">
                                            <v-btn v-bind="attrs"
                                                   v-on="on"
                                                   x-small
                                                   text
                                                   class="m-1 px-3"
                                                   color="primary">
                                                <b>{{ $t('Join') }}</b>
                                            </v-btn>
                                        </template>
                                    </join-dialog>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list dense
                        subheader
                        v-if="isShowPointsRow">
                    <v-subheader style="margin: 5px 5px -5px;">{{ $t('คะแนนของฉัน') }}</v-subheader>
                    <v-list-item v-if="userDataInShop.point_data">
                        <v-list-item-icon>
                            <v-icon class="white--text app-like-small blue-grey lighten-2"
                                    small>mdi-star-four-points-circle-outline
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ number_format(userDataInShop.point_data.available_balance) }} {{ $t('คะแนน') }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon style="justify-content: flex-end">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary"
                                            dark
                                            x-small
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>{{ $t('คะแนนสะสมที่ใช้ได้กับร้านค้า') }}</span>
                            </v-tooltip>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item v-if="my_lnwpoint_obj">
                        <v-list-item-icon>
                            <div class="white--text app-like-small blue-grey lighten-5">
                                <img style="display: inline-block; width: 16px; height: 16px; margin: 0 auto;"
                                     :src="require('@/assets/icon_lnwpoints.png')"/></div>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ number_format(my_lnwpoint_obj.available_balance) }} {{ $t('คะแนน') }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon style="justify-content: flex-end">
                            <v-tooltip top
                                       max-width="200">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary"
                                            dark
                                            x-small
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>{{ $t('คะแนนสะสม LnwPoints สามารถใช้เป็นส่วนลดได้ทุกร้านค้าที่ใช้งาน LnwPay') }}</span>
                            </v-tooltip>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
                <!--                <v-divider></v-divider>-->
                <v-list dense
                        subheader>
                    <v-subheader style="margin: 5px 5px 0px;">
                        {{ $t('รายการสั่งซื้อของฉัน') }}
                    </v-subheader>
                    <div v-for="(status, i) in tabItems"
                         :key="i">
                        <v-list-item link
                                     :class="(('tab-' + status) == currentStatus)?'blue-grey lighten-5':''"
                                     @click="changeOrderTab(status)">
                            <v-list-item-icon style="text-align: center">
                                <div class="white--text app-like-small"
                                     :class="orderMeta[status].color">
                                    <template v-if="status != 'all'">
                                        <img :src="require('@/assets/order_status/' + status + '.png')"
                                             style="margin: 0 auto; filter: grayscale(100%) brightness(100)"
                                             width="14"/>
                                    </template>
                                    <template v-else>
                                        <v-icon color="white"
                                                size="14">mdi-shopping
                                        </v-icon>
                                    </template>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-title style="line-height: 1.75"
                                               :class="(('tab-' + status) == currentStatus)?'font-weight-bold':''">
                                {{ orderMeta[status].text }}
                                <v-tooltip v-if="orderMeta[status].only_lnwpay"
                                           top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs"
                                                x-small
                                                color="(('tab-' + status) == currentStatus)?'white':'primary'"
                                                v-on="on">mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('เฉพาะรายการสั่งซื้อที่มีการชำระเงินผ่าน LnwPay') }}</span>
                                </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-action-text style="justify-content: flex-end">
                                <div v-if="orderByState[status] && orderByState[status].length > 0"
                                     class="grey--text"
                                     :class="('tab-' + status) == currentStatus ? 'font-weight-bold':''">
                                    <template v-if="status == 'all'">
                                        {{ number_format(total_orders_count, 0) }}
                                    </template>
                                    <template v-else>
                                        <div class="text-no-wrap">{{ number_format(orderByState[status].length, 0) }}<span class="minor"
                                                                                                                           v-if="paginated_metadata[status].more">+</span>
                                        </div>
                                    </template>
                                </div>
                            </v-list-item-action-text>
                        </v-list-item>
                    </div>
                    <v-list-group v-if="moreTabItems && moreTabItems.length"
                                  :value="false">
                        <template v-slot:activator>
                            <v-list-item-icon style="text-align: center">
                                <div class="white--text app-like-small">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="">{{ $t('เพิ่มเติม') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item style="padding-left: 20px"
                                     v-for="(status, i) in moreTabItems"
                                     @click="isDesktop?changeOrderTab(status):addTabItem(status)"
                                     :key="i"
                                     :class="(('tab-' + status) == currentStatus)?'blue-grey lighten-5':''"
                                     link>
                            <v-list-item-icon style="text-align: center">
                                <div class="white--text app-like-small"
                                     :class="orderMeta[status].color">
                                    <img :src="require('@/assets/order_status/' + status + '.png')"
                                         style="margin: 0 auto; filter: grayscale(100%) brightness(100)"
                                         width="14"/>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-title style="line-height: 1.75"
                                               :class="(('tab-' + status) == currentStatus)?'font-weight-bold':''">
                                {{ orderMeta[status].text }}
                            </v-list-item-title>
                            <v-list-item-action-text style="justify-content: flex-end">
                                <div v-if="orderByState[status] && orderByState[status].length > 0"
                                     class="grey--text"
                                     :class="('tab-' + status) == currentStatus ? 'font-weight-bold':''">
                                    <div class="text-no-wrap">{{ number_format(orderByState[status].length, 0) }}<span class="minor"
                                                                                                                       v-if="paginated_metadata[status].more">+</span>
                                    </div>
                                </div>
                            </v-list-item-action-text>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-list dense
                        subheader>
                    <v-subheader style="margin: 5px 5px -5px;">{{ $t('การชำระเงิน') }}</v-subheader>
                    <v-list-item>
                        <inform-payment-dialog :directInform="true"
                                               :orders-item="orderByCanInformPayment"
                                               :selected-order="selectedItems"
                                               @success-inform-action="successInformAction">
                            <template v-slot:activator="{on,attrs}">
                                <v-btn class="my-2 py-4"
                                       outlined
                                       small
                                       block
                                       color="primary"
                                       v-bind="attrs"
                                       v-on="on">{{ $t('แจ้งโอนเงิน (ส่งสลิป)') }}
                                </v-btn>
                            </template>
                        </inform-payment-dialog>
                    </v-list-item>
                    <div style="height: 100px;"></div>
                </v-list>
                <v-list v-if="!isDesktop"
                        class="bg-blurry sticky-bottom border-0">
                    <v-list-item>
                        <v-btn block
                               depressed
                               @click="myOrderDrawer = false"
                               class="my-2">
                            {{ $t('ปิดหน้าต่าง') }}
                        </v-btn>
                    </v-list-item>
                </v-list>
                <template v-slot:append
                          v-if="!isDesktop">
                    <!--                    <v-divider></v-divider>-->
                </template>
            </v-navigation-drawer>
        </template>
        <template #default>
            <v-tabs v-model="currentStatus"
                    center-active
                    color="primary">
                <template v-if="!isDesktop">
                    <v-tab v-for="tabItem in tabItems"
                           :key="tabItem"
                           :href="'#tab-' + tabItem"
                           class="text-capitalize text-decoration-none">
                        <template v-if="isWaitingStatus(tabItem) && (orderByState[tabItem] && orderByState[tabItem].length)">
                            <v-badge over
                                     dot
                                     :color="orderMeta[tabItem].color">
                                {{ orderMeta[tabItem].text }}
                            </v-badge>
                        </template>
                        <template v-else>
                            {{ orderMeta[tabItem].text }}
                        </template>
                    </v-tab>
                    <v-menu v-if="moreTabItems && moreTabItems.length"
                            bottom
                            left
                            offset-y
                            style="background: white;">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text
                                   style="letter-spacing: 0"
                                   class="align-self-center mr-4"
                                   v-bind="attrs"
                                   v-on="on">
                                {{ $t('เพิ่มเติม') }}
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list class="">
                            <v-list-item v-for="tabItem in moreTabItems"
                                         :key="tabItem"
                                         @click="addTabItem(tabItem)">
                                {{ orderMeta[tabItem].text }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-tabs>
            <v-tabs-items v-model="currentStatus"
                          touchless>
                <v-tab-item v-for="tabItem in orderMeta"
                            :value="'tab-' + tabItem.status"
                            :key="tabItem.status">
                    <div class="myOrderContainer">
                        <div class="myOrderContent">
                            <template v-if="paginated_metadata[tabItem.status].firstFetched
                                            && !paginated_metadata[tabItem.status].more
                                            && orderByState[tabItem.status]
                                            && orderByState[tabItem.status].length === 0">
                                <div class="d-flex align-items-center justify-center text-center min-height-lnwme">
                                    <div>
                                        <div v-if="tabItem.status != 'all'"
                                             class="mx-auto d-inline-block mb-2 p-2 circle40"
                                             style="line-height: 0"
                                             :class="tabItem.color">
                                            <img :src="require('@/assets/order_status/' + tabItem.status + '.png')"
                                                 style="filter : grayscale(100%) brightness(100); margin: 4px;"
                                                 width="16">
                                        </div>
                                        <img v-else
                                             :src="require('@/assets/sticker/empty_order.png')"
                                             style=" margin: 4px;"
                                             width="60">
                                        <div class=" opacity50">
                                            {{ $t('ไม่มีรายการสั่งซื้อ') }} - {{ tabItem.text }}
                                        </div>
                                        <v-btn large
                                               :href="shopData ? shopData.url : ''"
                                               color="primary"
                                               class="mt-4">
                                            {{ $t('สั่งซื้อสินค้าใหม่') }}
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                            <v-list v-else
                                    :style="(isDesktop)?'min-height: calc(100svh - 110px)':'min-height: calc(100svh - 120px)'"
                                    class="py-4">
                                <v-list-item class="my-4">
                                    <v-list-item-title>
                                        <div class="just_flex flex_wrap">
                                            <h3 style="line-height: 1.7;"
                                                class="mb-0"><b>{{ tabItem.text }}</b></h3>
                                            <v-chip class="text-no-wrap"
                                                    small
                                                    dark
                                                    :color="tabItem.color">
                                                <img v-if="tabItem.status != 'all'"
                                                     :src="require('@/assets/order_status/' + tabItem.status + '.png')"
                                                     style="filter : grayscale(100%) brightness(100); margin-right: 0.5rem; vertical-align: middle;"
                                                     width="10">
                                                <template v-if="tabItem.status == 'all'">
                                                    {{ number_format(total_orders_count, 0) }}
                                                </template>
                                                <template v-else>{{
                                                        orderByState[tabItem.status]
                                                                ? (
                                                                        number_format(
                                                                                orderByState[tabItem.status]
                                                                                        ? orderByState[tabItem.status].length
                                                                                        : 0
                                                                                , 0)
                                                                )
                                                                : ''
                                                    }}{{ paginated_metadata[tabItem.status].more ? '+' : '' }}
                                                </template>
                                                {{ $t('ออเดอร์') }}
                                            </v-chip>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item class="disabled">
                                    <v-list-item-subtitle style="line-height: 1.75">
                                        {{ tabItem.desc }}
                                        <template v-if="tabItem.status == 'all'">
                                            {{ shopData ? shopData.name : null }}
                                        </template>
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item v-for="order in orderByState[tabItem.status]"
                                             :key="order.order_id"
                                             class="my-8">
                                    <v-card elevation="24"
                                            style="border: 1px solid #edeef0;"
                                            class="text-decoration-none"
                                            :href="select_mode?'':order.cc_url"
                                            target="_blank">
                                        <v-list :style="selectedItems.includes(order.order_id)?'box-shadow: 0 4px 24px -12px #aaa':''">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title class="pt-2 pb-0 mb-0">
                                                        <div class="just_flex flex-nowrap">
                                                            <b style="max-width: 80%; overflow: hidden; text-overflow: ellipsis;">{{ order.product_items[0].name ?? '-' }}</b>
                                                            <template v-if="!select_mode">
                                                                <v-icon :small="!isDesktop"
                                                                        color="primary"
                                                                        style="vertical-align: text-bottom"
                                                                        class="ml-2">
                                                                    mdi-arrow-right
                                                                </v-icon>
                                                            </template>
                                                            <template v-else>
                                                                <v-scale-transition>
                                                                    <v-checkbox style="position: absolute; top: 0.25rem; right: 1.25rem;"
                                                                                :disabled="!(order.status == 'wait_payment' || order.status == 'wait_payment_verify')"
                                                                                :style="!(order.status == 'wait_payment' || order.status == 'wait_payment_verify')?'opacity: 0.3':''"
                                                                                v-model="selectedItems"
                                                                                :value="order.order_id"></v-checkbox>
                                                                </v-scale-transition>
                                                            </template>
                                                        </div>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="opacity50 color-light">
                                                        {{ clock.diff_now_text(order.order_time) }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <div class="flex_start"
                                                         style="gap:10px;">
                                                        <div v-for="item in order.product_items"
                                                             :key="item.id">
                                                            <v-badge color="grey darken-2"
                                                                     bordered
                                                                     overlap>
                                                                <template v-slot:badge>
                                                                    {{ item.quantity }}
                                                                </template>
                                                                <v-img class="rounded-lg"
                                                                       :src="item.thumbnail"
                                                                       :aspect-ratio="1"
                                                                       height="50"
                                                                       width="50"></v-img>
                                                            </v-badge>
                                                        </div>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle style="letter-spacing: 0.5px;">
                                                        <div class="just_flex">
                                                            <div v-if="false">
                                                                <div class="mx-auto d-inline-block rounded-xl ml-2"
                                                                     style="line-height: 0"
                                                                     :class="orderMeta[order.status].color">
                                                                    <img :src="require('@/assets/order_status/' + order.status + '.png')"
                                                                         style="filter : brightness(50); margin: 4px;"
                                                                         width="6">
                                                                </div>
                                                            </div>
                                                            <div class="color-light">
                                                                <v-tooltip v-if="order.is_lnwpay"
                                                                           top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <img v-bind="attrs"
                                                                             v-on="on"
                                                                             style="width: 14px; vertical-align: text-top"
                                                                             :src="require('@/assets/lnwpay_shield_square.png')"
                                                                             alt="LnwPay">
                                                                    </template>
                                                                    <span style="text-transform: capitalize">{{ $t('ชำระผ่าน LnwPay') }}</span>
                                                                </v-tooltip>
                                                                {{ $t('บิลที่') }} {{ order.order_id }} ·
                                                                <span :class="orderMeta[order.status].text_color">{{ orderMeta[order.status].text }}</span>
                                                            </div>
                                                            <div>
                                                                <span class="grey--text text--darken-4">฿{{ number_format(order.net_price, 2) }}</span>
                                                            </div>
                                                        </div>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-list-item>
                                <div v-if="paginated_metadata[tabItem.status].more">
                                    <v-list rounded
                                            class="mb-16"
                                            style="margin: 0 20px;">
                                        <v-list-item @click="isAjaxLoading?null:loadMoreOrders()">
                                            <v-list-item-action>
                                                <v-icon v-if="!isAjaxLoading">mdi-chevron-down</v-icon>
                                                <v-icon v-else>mdi-progress-download</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title style="margin-bottom: 0">
                                                    <span v-if="!isAjaxLoading"
                                                          style="font-weight: normal">{{ $t('โหลดรายการสั่งซื้อเพิ่มเติม') }}</span>
                                                    <span v-else
                                                          style="font-weight: normal">{{ $t('กำลังดาวน์โหลด') }}...</span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                                <v-list-item v-else>
                                    <div style="display: inline-block; margin: 0 auto 6rem; height: 6px; border-radius: 6px; width: 100px; background: #ddd"></div>
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </LnwmeLayout>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import LnwmeLayout from "@/views/layouts/LnwmeLayout.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsOrderMeta from "@/mixins/MixinsOrderMeta";
import {clock} from "@/libraries/DateTime.js";
import Helper from "@/libraries/Helper";
import JoinDialog from "@/components/Order/components/JoinComponent";
import InformPaymentDialog from "@/components/Order/components/PaymentMethod/InformPaymentDialog";
import {SET_INFORM_DIALOG} from "@/store/modules/order/mutation-types";
import {SET_IS_AJAX_RESPONSE, SET_IS_AJAX_RESPONSE_MESSAGE} from "@/store/modules/order/mutation-types";

export default {
    name: "MyOrders",
    components: {
        LnwmeLayout,
        JoinDialog,
        InformPaymentDialog,
    },
    mixins: [
        MixinsHelper,
        MixinsOrderMeta
    ],
    data(){
        return {
            select_mode: false,
            selectedItems: [],
            currentStatus: 'tab-all',
            moreStatus: ['expired', 'refund', 'buyer_cancelled', 'seller_cancelled', 'suspended'],
            myOrderDrawer: false,
        }
    },
    computed: {
        ...mapState({
            userData: state => state.user.userData,
            shopData: state => state.myOrders.shopData,
            userDataInShop: state => state.myOrders.userDataInShop,
            // object lnwpoint จาก API lnwpay
            my_lnwpoint_obj: state => {
                return state.lnwpay.point
                    ? state.lnwpay.point
                    : {
                        available_balance: null,
                        available_money: null,
                        point_value: null,
                    };
            },
            paginated_metadata: state => state.myOrders.paginated_metadata,
            isAjaxLoading: state => state.order.isAjaxLoading,
        }),
        ...mapGetters({
            orders: "myOrders/orders",
            sortedOrders: "myOrders/sortedOrders",
            total_orders_count: "myOrders/total_orders_count",
            isLogin: "user/isLogin",
        }),
        informDialog: {
            get(){
                return this.$store.state.order.informDialog;
            },
            set(value){
                this.$store.commit('order/' + SET_INFORM_DIALOG, value);
            },
        },
        isAjaxResponse: {
            get(){
                return this.$store.state.order.isAjaxResponse;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE, value);
            },
        },
        isAjaxResponseMessage: {
            get(){
                return this.$store.state.order.isAjaxResponseMessage;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE_MESSAGE, value);
            },
        },
        isCopied: {
            get(){
                return this.$store.state.order.isCopied;
            },
            set(value){
                this.$store.dispatch('order/setIsCopied', value);
            },
        },
        actualStatus(){
            let state = this.currentStatus.substr(4);
            return state;
        },
        pageReady(){
            if(this.userData && this.isLogin && this.shopData && typeof this.sortedOrders !== 'undefined'){
                return true;
            }
            return false;
        },
        tabItems(){
            if(!this.orderMeta) return [];
            let that = this;
            return this.allOrderStatus.filter((status) => !that.moreStatus.includes(status))
        },
        moreTabItems(){
            if(!this.orderMeta) return [];
            let that = this;
            return this.allOrderStatus.filter((status) => that.moreStatus.includes(status))
        },
        orderByState(){
            let obj = {};
            let that = this;
            if(this.sortedOrders){
                this.allOrderStatus.forEach(function (status){
                    const sortedOrdersArray = Object.values(that.sortedOrders).reverse();

                    if(status == 'all'){
                        obj[status] = sortedOrdersArray;
                    }else{
                        obj[status] = sortedOrdersArray.filter((order) => order.status == status);
                    }
                });
            }
            return obj;
        },
        orderByStatusWaitPayment(){
            if(this.orderByState && Object.keys(this.orderByState).length){
                return this.orderByState['wait_payment'];
            }
            return [];
        },
        orderByCanInformPayment(){
            if(this.orderByState && Object.keys(this.orderByState).length){
                return this.orderByState['wait_payment'].concat(this.orderByState['wait_payment_verify']);
            }
            return [];
        },
        clock(){
            return clock;
        },
        isShowPointsRow(){
            return this.userDataInShop.point_data || this.my_lnwpoint_obj
        },
    },

    methods: {
        ...mapActions({
            _loadMoreOrders: 'myOrders/loadMoreOrders',
            actionFetchOrders: "myOrders/fetchOrders",
        }),
        async loadMoreOrders(load_all_wait_payment = false){
            if(this.isAjaxLoading){
                return false;
            }
            let load_status = load_all_wait_payment ? 'wait_payment' : this.actualStatus;
            if(this.paginated_metadata[load_status].more){
                await this._loadMoreOrders({status: load_status, limit: (load_all_wait_payment) ? '100' : '20'});
            }
        },
        addTabItem(item){
            this.moreStatus.splice(this.moreStatus.indexOf(item), 1)
            this.$nextTick(() => {
                this.currentStatus = 'tab-' + item;
                if(this.myOrderDrawer && !this.isDesktop){
                    this.myOrderDrawer = false;
                }
            })
        },
        changeOrderTab(status){
            this.currentStatus = 'tab-' + status;
            if(!this.isDesktop){
                this.$nextTick(() => {
                    this.myOrderDrawer = false;
                })
            }
        },
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        isWaitingStatus(status){
            return status.includes('wait');
        },
        successInformAction(){
            this.actionFetchOrders();
            this.select_mode = false;
            this.selectedItems = [];
        },
        async selectAllWaitPayment(){
            await this.loadMoreOrders(true);
            this.selectedItems = this.orderByStatusWaitPayment.map(order => order.order_id);
        }
    },
    mounted(){
        let state = this.$route.query.state;
        let status = this.$route.query.status;
        if(this.orderMeta[state]){
            this.currentStatus = 'tab-' + state;
        }else if(this.orderMeta[status]){
            this.currentStatus = 'tab-' + status;
        }
    },
    watch: {
        actualStatus(status){
            //เวลาเปลี่ยน tab ไม่ให้คง scrollTop เดิมไว้
            let ele = document.getElementById('lnwme-layout-content');
            if(ele)
                document.getElementById('lnwme-layout-content').scrollTop = 0;
            // console.log(this.currentStatus);

            if(!this.paginated_metadata[status].firstFetched
                && this.orderByState[status]
                && (
                    this.orderByState[status].length === 0
                    || ele.scrollTop + ele.clientHeight >= ele.scrollHeight
                )
            ){
                this.loadMoreOrders();
            }
        },
        currentStatus(){

            if(!this.currentStatus){
                this.$nextTick(() => {
                    this.currentStatus = 'tab-all';
                });
            }
            if(this.currentStatus){
                if(this.$route.query.state && this.$route.query.state != this.actualStatus){
                    this.$router.replace({query: {state: this.actualStatus}});
                }
            }
        }
    }
};
</script>
<style scoped></style>